document.addEventListener('DOMContentLoaded', () => {
    const navbarTogglerEl = document.querySelector(".navbar-toggler");
    const navbarEl = document.getElementById("navbarSupportedContent");
    let hideElements = document.querySelectorAll('.navbar-collapse-hide');
    if (navbarTogglerEl && navbarEl && hideElements){
      hideElements.forEach((hideEl) => {
        hideEl.addEventListener('click', (event) => {
            navbarEl.classList.toggle('show');
            navbarTogglerEl.classList.toggle('collapsed');
            navbarTogglerEl.setAttribute("aria-expanded", false);
        });
      });
    }
        
});